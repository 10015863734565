import React from "react";
import serviceS1 from "@/images/mobile.png";
import serviceS2 from "@/images/planingg.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      <img src={serviceS1} alt="" />
      <h2>App -Entwicklung</h2>
      <p>
        Bei Soltriks sind wir darauf spezialisiert, konzeptionelle App -Ideen in
        die Transformation zu bringen Greifbare Realitäten.Ob Sie ein
        wegweisendes Startup mit a repräsentieren Visionäres Konzept oder ein
        etabliertes Unternehmen, das Innovation sucht, unsere, unsere Das Team
        erfahrener Entwickler und Designer ist bereit, zu verwirklichen Ihre
        Bestrebungen.Wir sind der festen Überzeugung, dass eine erfolgreiche App
        -Entwicklung entwickelt wurde transzendiert bloße Codierung;Es erfordert
        ein tiefes Verständnis Ihrer Ziele, Zielgruppe und einzigartige
        Herausforderungen.Aus Konzeptualisierung bis zum Einsatz und
        fortlaufende Unterstützung, wir Arbeiten Sie eng mit Ihnen zusammen, um
        Anwendungen herzustellen, die sich nicht nur erfüllen Ihre technischen
        Spezifikationen, übertreffen aber auch Ihre Erwartungen in Begriffe der
        Designästhetik, Benutzerfreundlichkeit und Leistung.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Planung und Strategie</h3>
          <p>Dienstleistungen, die wir anbieten:</p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>iOS App Development &
              Android App -Entwicklung
            </li>
            <li>
              <i className="fa fa-check-square"></i>Qualitätssicherung und
              Testen
            </li>
            <li>
              <i className="fa fa-check-square"></i>Plattformübergreifende
              Entwicklung
            </li>
            <li>
              <i className="fa fa-check-square"></i>UI/UX -Design: Erstellen
              Intuitiv, benutzerfreundlich
            </li>
            <li>
              <i className="fa fa-check-square"></i>App -Wartung und
              -unterstützung
            </li>
          </ul>
        </div>
      </div>
      <p>
        Egal, ob Sie ein Startup sind, um eine starke Markenidentität
        festzulegen oder ein etabliertes Unternehmen, das darauf abzielt, Ihre
        visuelle Präsenz zu aktualisieren, Soltriks ist hier, um Ihnen zu
        helfen.Kontaktieren Sie uns noch heute, um Ihr Design zu besprechen
        Bedürfnisse und tun Sie den ersten Schritt, um Ihre Marke in a zu
        verwandeln visuelles Meisterwerk.
      </p>
      <p>
        Bei Soltriks erkennen wir an, dass die Grundlage eines erfolgreichen
        Webs Entwicklungsprojekt liegt in der akribischen Planung.Vor dem
        Schreiben einer Single Codelinie oder Entwerfen eines einzelnen Pixels
        investieren wir Zeit in die Zeit Verstehen Sie Ihre Geschäftsziele,
        Zielgruppen und einzigartig Anforderungen.Unser umfassender
        Planungsprozess sorgt dafür Aspekt Ihres Projekts wird sorgfältig
        berücksichtigt und mit ausgeführt Präzision.
      </p>
    </div>
  );
};

export default ServiceDetailsContent;
